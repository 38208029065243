.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*Columns*/
.container .row .col {
  /*margin-bottom: 50px;*/

}


#validation-message {
  /*display: inline-block;*/
  padding-left: 2em;
  margin-top: 0.5em;
  background-color: #e2582266;
  font-size: small;
  font-family: Courier, serif;
}

.annotation {
  /*max-height: 550px;*/
  overflow-y: auto;
  max-width: 100%;
  overflow-x: auto;
}

.react-transform-wrapper {
  /*max-height: 550px;*/
  /*overflow: scroll !important;*/
  /*max-width: 100%;*/
  transform-origin: top left;
}

.placeholder {
  border: 1px solid black;
  border-radius: 4px;
  padding: 0 3px;
  margin-right: 5px;
  font-family: Helvetica, serif;
  background-color: #6bb89b;
  opacity: 1;
  cursor: auto;
  /*cursor: grab;*/
}

/*.placeholder:active {*/
/*  cursor: grabbing;*/
/*}*/

.cm-editor.cm-focused {
  outline: none !important;
}

.container {
  max-width: 100%;
  margin-top: 0;
}

.layout {
  margin-bottom: 80px;
}





.switcher {
  display: inline-block;
  padding: 2px;
  background: #fff;
  width: 150px;
  border-radius: 30px;
  border: solid 1px #ddd;
  position: relative;
}

.switcher__input {
  display: none;
}

.switcher__label {
  float: left;
  width: 50%;
  font-size: 12px;
  line-height: 20px;
  color: #000;
  text-align: center;
  cursor: pointer;
  position: inherit;
  z-index: 10;
  transition: color 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
  will-change: transform;
}

.switcher__toggle {
  position: absolute;
  float: left;
  height: 20px;
  width: 50%;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
  background-color: black;
  border-radius: 30px;
  left: 2px;
  top: 2px;
  transition: left 0.25s cubic-bezier(0.4, 0.0, 0.2, 1);
  will-change: transform;
}

.switcher__input:checked + .switcher__label {
  color: #fff;
}

.switcher__input--pan:checked ~ .switcher__toggle {
  left: 72px;
}



.a9s-annotation .a9s-inner, .a9s-annotation .a9s-outer {
  stroke-width: 1px;
  stroke-opacity: 50%;
}

.a9s-annotation:hover .a9s-inner {
  stroke-width: 2px;
  stroke-opacity: 80%;
}

.a9s-annotation.selected .a9s-inner, .a9s-selection .a9s-inner {
  stroke-width: 5px;
  stroke-opacity: 100%;
}